import axios from 'axios'

import logoLombok from './logo-lombok.png'
import logoBali from './logo-bali.png'
import logoSurabaya from './logo-surabaya.png'
import logoMakassar from './logo-makassar.png'
import logoSudiang from './logo-sudiang.png'

import sideImageBali from './aset/sidebar-bali.jpg'
import sideImageLombok from './aset/sidebar-lombok.jpg'
import sideImageSurabaya from './aset/sidebar-surabaya.jpg'
import sideImageMakassar from './aset/sidebar-makassar.jpg'
import sideImageSudiang from './aset/sidebar-sudiang.jpg'

class Api {
    // static baseUrl = 'http://localhost:3000/shop/'
    // static baseUrl = 'https://amigoo.sawopos.com/shop/'

    static defaultCabang = "Lombok"
    // static defaultCabang = "Bali"
    // static defaultCabang = "Surabaya"
    // static defaultCabang = "Makassar"
    static websiteUrl = 'https://' + Api.defaultCabang.toLowerCase() + '.amigoo.id/'

    // static tokoId = 'toko1'
    // static tokoId = 'toko3'
    // static tokoId = 'toko4'

    static getTokoId() {
        let c = Api.defaultCabang.toLocaleLowerCase()
        if (c === 'lombok') {
            return 'toko1'
        } else if (c === 'bali') {
            return 'toko3'
        } else if (c === 'surabaya') {
            return 'toko4'
        } else if (c === 'makassar') {
            return 'toko5'
        } else if (c === 'sudiang') {
            return 'toko9'
        }
        return 'tokoxx'
    }

    static baseUrlFoto = Api.getBaseUrl() + 'foto/'
    static baseUrlThumb1 = Api.getBaseUrl() + 'thumb1/'
    static baseUrlThumb2 = Api.getBaseUrl() + 'thumb2/'

    static getShowHargaGrosir() {
        return (this.defaultCabang === "Surabaya") || (this.defaultCabang === "Makassar")
    }

    static getGudangId() {
        return parseInt(localStorage.getItem("gudangId") || -1)
    }

    static setGudangId(gudangId) {
        localStorage.setItem("gudangId", gudangId + "")
    }

    static getBaseUrl() {
        let c = Api.defaultCabang
        if (c === 'Lombok') {
            return 'https://amigoolombok.sawopos.com/shop/'
            // return 'http://localhost:3000/shop/'
        } else if (c === 'Bali') {
            return 'https://amigoobali.sawopos.com/shop/'
        } else if (c === 'Surabaya') {
            return 'https://amigoosurabaya.sawopos.com/shop/'
        } else if (c === 'Makassar') {
            return 'https://amigoomakassar.sawopos.com/shop/'
        } else if (c === 'Sudiang') {
            return 'https://amigoosudiang.sawopos.com/shop/'
        }
    }

    static getLogo() {
        let c = Api.defaultCabang
        if (c === 'Lombok') {
            return logoLombok
        } else if (c === 'Bali') {
            return logoBali
        } else if (c === 'Surabaya') {
            return logoSurabaya
        } else if (c === 'Makassar') {
            return logoMakassar
        } else if (c === 'Sudiang') {
            return logoSudiang
        }
    }

    static getSideImage() {
        let c = Api.defaultCabang
        if (c === 'Lombok') {
            return sideImageLombok
        } else if (c === 'Bali') {
            return sideImageBali
        } else if (c === 'Surabaya') {
            return sideImageSurabaya
        } else if (c === 'Makassar') {
            return sideImageMakassar
        } else if (c === 'Sudiang') {
            return sideImageSudiang
        }
    }

    static async login(user, password) {
        let api
        try {
            let res = await axios.post(Api.getBaseUrl() + 'login2', {
                user,
                password
            })
            api = res.data
        } catch (er) {
            console.error(er)
        }
        return api
    }

    static logout() {
        localStorage.setItem("token", "")
    }

    static setToken(token, user) {
        localStorage.setItem("token", token)
        localStorage.setItem("user", user)
    }

    static getUser() {
        return localStorage.getItem("user") || ""
    }

    static isLogin() {
        if (Api.getShowHargaGrosir()) {
            return true
        }
        let token = localStorage.getItem("token") || ""
        return token.length > 0
    }

    static async getHomeData(gudangId) {
        let api
        try {
            let res = await axios.post(Api.getBaseUrl() + 'home2', {
                gudangid: gudangId
            })
            api = res.data
        } catch (er) {
            console.error(er)
        }
        return api
    }

    static async getDaftarBarang(cari, kategoriId, brandId, skip, take, sort = 'created desc', gudangId = -1) {
        let api = {
            ok: false,
            msg: ''
        }
        let res = await axios.post(Api.getBaseUrl() + 'caribarang', { cari, kategoriId, brandId, skip, take, sort, gudangid: gudangId })
        api = res.data
        return api
    }

    static async getDaftarKategori() {
        let api = {
            ok: false,
            msg: ''
        }
        let res = await axios.post(Api.getBaseUrl() + 'kategori')
        api = res.data
        return api
    }

    static async getDaftarFooter(gudang_id) {
        let api = {
            ok: false,
            msg: ''
        }
        let res = await axios.post(Api.getBaseUrl() + 'footers', { gudang_id })
        api = res.data
        return api
    }

    static async getBarang(id) {
        let api = {
            ok: false,
            msg: ''
        }
        let res = await axios.post(Api.getBaseUrl() + 'detailbarang/' + id)
        api = res.data
        return api
    }

    static async getDaftarGudang() {
        let ret = []
        let res = await axios.post(Api.getBaseUrl() + 'daftargudang')
        ret = res.data
        return ret
    }
}

export default Api